var $ = window.$;

if (!$) {
  throw new Error('jQuery not initialized or missing.');
}

var Application = function () {
  this.disable_form_submit = true;
  this.missing_checkbox_terms = true;
  this.missing_checkbox_privacy = true;
  this.initialize();

  this.update_form(window.mailchimp_email, window.mailchimp_unique_id);

  window.addEventListener('resize', this.resize.bind(this), false);
  window.addEventListener('load', this.load.bind(this), false);

  return this;
};

Application.prototype.initialize = function () {
  var self = this;
  var $template = $('body');
  var $form = $template.find('form#mc-merged-form');
  var $submit_button = $template.find('#mc-embedded-subscribe');

  if ($submit_button.hasClass('disabled')) {
    return;
  }

  var reset_form = function (hide) {
    $form[0].reset();
    $form.find('[name]').each(function () {
      $(this).removeClass('error');
    });
    $form.find('.checkbox-list > li').each(function () {
      $(this).find('input[type="checkbox"]').removeAttr('checked');
      $(this).removeClass('selected');
    });
    self.alert(null, null);

    if (hide) {
      $form.hide();
    } else {
      $form.show();
    }
  };

  var field_check = function ($el) {
    var type = $el.attr('type');
    var value = $el.val() || '';
    var valid = true;
    var is_required = $el.hasClass('required');

    if (type === 'text') {
      valid = (value.length);
    } else if (type === 'email') {
      valid = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value);
    } else if (type === 'url') {
      if (value.length) {
        value = value.replace(/\s/g, '');
      }
      valid = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
    } else if (type === 'number') {
       valid = /^\d+(?:\.\d{1,2})?$/.test(value);
    } else if (type === 'checkbox' && is_required) {
      valid = $el.attr('checked');
      if(valid){
        if($el.attr('name') === 'checkbox_terms'){
            self.missing_checkbox_terms = false;
        }
        else if($el.attr('name') === 'checkbox_privacy'){
            self.missing_checkbox_privacy = false;
        }
        return;
      }
    }

    if ((value.toString().length <= 0) && !is_required) {
      valid = true;
    } else if (is_required) {
      self.disable_form_submit = !valid;
    }

    $el.removeClass('error').addClass(valid ? '': 'error');
  };

  this.responsive();
  this.dropzoner($form);

  $template.find('input').keyup(function () { field_check($(this)); });
  $template.find('[name]').change(function () { field_check($(this)); });

  $template.find('.site').bind('scroll', function (e) {
    var height = $(this).scrollTop();

    $template.removeClass('collapse-nav').addClass((height > 60) ? 'collapse-nav': '');
  });

  $template.find('[data-action="panel_open"]').click(function (e) {
    $template.find('.panel').animate({
      left: '0px'
    }, 200, function () {
      $submit_button.removeClass('disabled');
      $(this).addClass('darkness');
    });
  });

  $template.find('[data-action="panel_close"]').click(function (e) {
    $template.find('.panel').removeClass('darkness').delay(200).animate({
      left: $(window).width() + 'px'
    }, 200, function () {
      $(this).css('left', $(window).width() + 'px');
      if (!self.update_mode) {
        reset_form();
      }
    });
  });

  $form.find('.checkbox-list > li').click(function () {
    $(this).toggleClass('selected');

    if ($(this).hasClass('selected')) {
      $(this).find('input[type="checkbox"]').attr('checked', true);
    } else {
      $(this).find('input[type="checkbox"]').removeAttr('checked');
    }
  });

  $form.submit(function (e) {
    e.preventDefault();
    e.stopPropagation();

    var merge_fields = {};
    var email = null;

    $form.find('[name]').each(function () {
      var attr = $(this).attr('name');
      var value = $(this).val();

      if (attr === 'EMAIL' && value) {
        email = value;
      }

      field_check($(this));
      merge_fields[attr] = value;
    });

    if (self.disable_form_submit && (self.missing_checkbox_terms || self.missing_checkbox_privacy)) {
        self.alert('error', window.cf_error_checkboxes_missing || 'Please accept our terms of use and privacy policy.');
        return;
    }

    if (self.disable_form_submit && !self.missing_checkbox_terms && !self.missing_checkbox_privacy) {
      self.alert('error', window.cf_error_missing_fields || 'Missing or invalid values. Please check your input.');
      return;
    }

    if (self.update_mode && !self.update_request_id) {
      self.alert('error', window.cf_error_invalid_userid || 'Invalid User ID. Request denied by server.');
      return;
    }

    $.ajax({
      url: (self.update_mode) ? '/api/profile/' + self.update_request_id : '/api/register',
      type: (self.update_mode) ? 'PATCH' : 'POST',
      data: $form.serialize(),
      beforeSend: function () {
        self.alert(null, null);
        $submit_button.addClass('disabled');
      },
      success: function (data) {
        var text = window.cf_msg_confirmation || 'To complete the registration please click the confirmation link we\'ve just sent you to your e-mail address.';
          
        if (self.update_mode) {
          text = window.cf_msg_profile_updated || 'Your profile has been updated';
          
          self.alert('success', text);
          setTimeout(function () {
            window.location.href = '/';
          }, 2000);
          return;
        }

        self.alert('success', text);
        setTimeout(function () {
          reset_form();
          window.location.href = '/';
        }, 2000);

        if (window.fbq) {
          fbq('track', 'CompleteRegistration');
        }
      },
      error: function (xhr, response, source) {
        var responseText = xhr.responseText;
        var defaultText = window.cf_msg_default || 'An error occurred during registration, please check your entries. If the problem continues, please contact us at freelance@contentfleet.com';

        try {
          responseText = JSON.parse(responseText);

          if (responseText && responseText.title === 'Member Exists') {
            self.alert('error', window.cf_error_email_exists || 'The e-mail address already exists.');
          } else {
            self.alert('error', defaultText);
          }

        } catch (e) {
          self.alert('error', defaultText);
        }
      },
      complete: function () {
        $submit_button.removeClass('disabled');
      }
    });
  });

  $template.find('#mc-form-submit').click(function () {
    $form.submit();
  });
};

Application.prototype.update_form = function (email, unique_id) {
  var self = this;
  var $template = $('body');
  var $form = $template.find('form#mc-merged-form');
  var $submit_button = $template.find('#mc-embedded-subscribe');

  if (email && unique_id) {
    this.update_mode = true;
    $template.find('.signup-button').text(window.cf_msg_update_profile || 'Update my profile');

    $.ajax({
      url: '/api/profile?email='+ email + '&e='+ unique_id,
      type: 'GET',
      success: function (data) {
        self.update_request_id = window.mailchimp_email_hash;

        var fields = data.merge_fields;
        var interests = data.interests;

        if (fields) {
          for (var key in fields) {
            if (fields.hasOwnProperty(key)) {
              var field_value = fields[key];

              if (key === 'ADDRESS') {
                for (var addr_key in field_value) {
                  if (field_value.hasOwnProperty(addr_key)) {
                    var addr_value = field_value[addr_key] || '';

                    if (addr_key === 'country') {
                      var country_value = self.iso_countries[addr_value.toLowerCase()];
                      var _temp = Object.keys(self.mailchimp_countries).filter(function(_key) {
                        return self.mailchimp_countries[_key] === country_value;
                      });

                      addr_value = (_temp && _temp.length) ? _temp[0] : null;
                    }

                    $form.find('[name="ADDRESS['+ addr_key +']"]').val(addr_value);
                  }
                }

              } else {
                $form.find('[name="'+ key +'"]').val(field_value);
              }
            }
          }

          if (interests) {
            for (var interest_key in interests) {
              if (interests.hasOwnProperty(interest_key)) {
                var interest_value = interests[interest_key];

                if (interest_value) {
                  $form.find('[name="interests['+ interest_key +']"]').parent().addClass(interest_value ? 'selected' : '');
                }
                $form.find('[name="interests['+ interest_key +']"]').attr('checked', interest_value);
              }
            }
          }

          $form.find('[name="EMAIL"]').val(email);
        }

        $template.find('.panel').animate({
          left: '0px'
        }, 200, function () {
          $submit_button.removeClass('disabled');
          $(this).addClass('darkness');
        });
      },
      error: function (xhr, response, source) {
        console.error(xhr, response)
      }
    });
  } else {
    this.update_mode = false;
  }
};

Application.prototype.dropzoner = function ($form) {
  var self = this;
  var options = {
    maxFilesize: 10,
    maxFiles: 1,
    addRemoveLinks: true,
    uploadMultiple: false,
    dictDefaultMessage: window.cf_msg_upload || 'Drag a file into this area or click to select a .pdf or .doc file (max. 10MB)',
    dictRemoveFile: window.cf_msg_remove_file || 'Remove file',
    accept: function (file, done) {
      // Check only for PDF and DOC formated REGEX TODO
      done();
    }
  };

  var resume = new Dropzone('#resume-upload', options);
  var worksample = new Dropzone('#worksample-upload', options);

  Dropzone.autoDiscover = false;

  resume.on('success', function (file) {
    var response = (file.xhr && file.xhr.responseText);
    if (response) {
      try {
        var data = JSON.parse(response);
        if (data.url) {
          $form.find('input[name="CVURL"]').attr('value', data.url);
        }

        self.alert('success', window.cf_msg_upload_success || 'Uploaded successfully.', 5000);
      } catch (e) {
        self.alert('error', window.cf_msg_upload_failed || 'Failed to upload file due to server error. Try again.');
      }
    }
  });

  resume.on('error', function (error, message) {
    if (message.indexOf('too big') !== -1) {
      message = window.cf_error_file_too_large || 'The file you are trying to upload is too large. The maximum allowed size of the file is 10MB.';
    }
    self.alert('error', message, 5000);
  });

  worksample.on('success', function (file) {
    var response = (file.xhr && file.xhr.responseText);
    if (response) {
      try {
        var data = JSON.parse(response);
        if (data.url) {
          $form.find('input[name="WORKSAMPLE"]').attr('value', data.url);
        }

        self.alert('success', window.cf_msg_upload_success || 'Uploaded successfully.', 5000);
      } catch (e) {
        self.alert('error', window.cf_msg_upload_failed || 'Failed to upload file due to server error. Try again.');
      }
    }
  });
};

Application.prototype.device = (function () {
  var screenWidth = window.innerWidth || $(window).width();
  var screens = {
    mobile: {
      id: 'xs',
      slug: 'ExtraSmall',
      minWidth: 0,
      maxWidth: 640
    },
    tablet: {
      id: 'sm',
      slug: 'Small',
      minWidth: 641,
      maxWidth: 1024
    },
    netbook: {
      id: 'md',
      slug: 'Medium',
      minWidth: 1025,
      maxWidth: 1280
    },
    desktop: {
      id: 'lg',
      slug: 'Large',
      minWidth: 1281,
      maxWidth: 9999
    }
  };

  for (var device in screens) {
    if (screens.hasOwnProperty(device)) {
      if (screenWidth >= screens[device].minWidth && screenWidth <= screens[device].maxWidth) {
        return screens[device].id;
      }
    }
  }
});

Application.prototype.alert = function (type, message, timer) {
  var $template = $('body');
  var $status_text = $template.find('#status-text');
  var $panel_container = $template.find('.panel > .container');

  if (message) {
    $status_text.removeClass('error success')
      .addClass(type === 'success' ? 'success' : 'error')
      .html(message).fadeIn();

    $panel_container.animate({ scrollTop: $panel_container[0].scrollHeight }, 300);
    setTimeout(function () { $status_text.fadeOut(); }, parseInt(timer || 15000));
  } else {
    $status_text.empty().hide();
  }
};

Application.prototype.responsive = function () {
  var $template = $('body');
  var window_width = $(window).width();
  var window_height = $(window).height();
  var main_headline_height = $template.find('.section-adjust').height();
  var device = this.device();
  var panel_distance = $template.find('.panel').css('left');

  if (panel_distance !== '0px') {
    $template.find('.panel').css('left', $(window).width() + 'px');
  }

  $template.find('.section-adjust').css('margin-top', ((window_height/2) - (main_headline_height/1.2)) + 'px');

  if (device === 'sm' || device === 'xs') {
    window_height = window_height + 70;
  }

  $template.find('#start').height(window_height + 'px');
};

Application.prototype.resize = function (e) {
  this.responsive();
};

Application.prototype.load = function (e) {
  // DO Something
};

Application.prototype.iso_countries = {
  "af": "Afghanistan",
  "ax": "Åland Islands",
  "al": "Albania",
  "dz": "Algeria",
  "as": "American Samoa",
  "ad": "Andorra",
  "ao": "Angola",
  "ai": "Anguilla",
  "aq": "Antarctica",
  "ag": "Antigua and Barbuda",
  "ar": "Argentina",
  "am": "Armenia",
  "aw": "Aruba",
  "au": "Australia",
  "at": "Austria",
  "az": "Azerbaijan",
  "bs": "Bahamas",
  "bh": "Bahrain",
  "bd": "Bangladesh",
  "bb": "Barbados",
  "by": "Belarus",
  "be": "Belgium",
  "bz": "Belize",
  "bj": "Benin",
  "bm": "Bermuda",
  "bt": "Bhutan",
  "bo": "Bolivia",
  "bq": "Bonaire",
  "ba": "Bosnia and Herzegovina",
  "bw": "Botswana",
  "bv": "Bouvet Island",
  "br": "Brazil",
  "io": "British Indian Ocean Territory",
  "bn": "Brunei Darussalam",
  "bg": "Bulgaria",
  "bf": "Burkina Faso",
  "bi": "Burundi",
  "kh": "Cambodia",
  "cm": "Cameroon",
  "ca": "Canada",
  "cv": "Cape Verde",
  "ky": "Cayman Islands",
  "cf": "Central African Republic",
  "td": "Chad",
  "cl": "Chile",
  "cn": "China",
  "cx": "Christmas Island",
  "cc": "Cocos (Keeling) Islands",
  "co": "Colombia",
  "km": "Comoros",
  "cg": "Congo",
  "cd": "Congo",
  "ck": "Cook Islands",
  "cr": "Costa Rica",
  "ci": "Côte d'Ivoire",
  "hr": "Croatia",
  "cu": "Cuba",
  "cw": "Curaçao",
  "cy": "Cyprus",
  "cz": "Czech Republic",
  "dk": "Denmark",
  "dj": "Djibouti",
  "dm": "Dominica",
  "do": "Dominican Republic",
  "ec": "Ecuador",
  "eg": "Egypt",
  "sv": "El Salvador",
  "gq": "Equatorial Guinea",
  "er": "Eritrea",
  "ee": "Estonia",
  "et": "Ethiopia",
  "fk": "Falkland Islands (Malvinas)",
  "fo": "Faroe Islands",
  "fj": "Fiji",
  "fi": "Finland",
  "fr": "France",
  "gf": "French Guiana",
  "pf": "French Polynesia",
  "tf": "French Southern Territories",
  "ga": "Gabon",
  "gm": "Gambia",
  "ge": "Georgia",
  "de": "Germany",
  "gh": "Ghana",
  "gi": "Gibraltar",
  "gr": "Greece",
  "gl": "Greenland",
  "gd": "Grenada",
  "gp": "Guadeloupe",
  "gu": "Guam",
  "gt": "Guatemala",
  "gg": "Guernsey",
  "gn": "Guinea",
  "gw": "Guinea-Bissau",
  "gy": "Guyana",
  "ht": "Haiti",
  "hm": "Heard Island and McDonald Islands",
  "va": "Holy See (Vatican City State)",
  "hn": "Honduras",
  "hk": "Hong Kong",
  "hu": "Hungary",
  "is": "Iceland",
  "in": "India",
  "id": "Indonesia",
  "ir": "Iran",
  "iq": "Iraq",
  "ie": "Ireland",
  "im": "Isle of Man",
  "il": "Israel",
  "it": "Italy",
  "jm": "Jamaica",
  "jp": "Japan",
  "je": "Jersey",
  "jo": "Jordan",
  "kz": "Kazakhstan",
  "ke": "Kenya",
  "ki": "Kiribati",
  "kp": "Korea",
  "kr": "Korea",
  "kw": "Kuwait",
  "kg": "Kyrgyzstan",
  "la": "Lao People's Democratic Republic",
  "lv": "Latvia",
  "lb": "Lebanon",
  "ls": "Lesotho",
  "lr": "Liberia",
  "ly": "Libya",
  "li": "Liechtenstein",
  "lt": "Lithuania",
  "lu": "Luxembourg",
  "mo": "Macao",
  "mk": "Macedonia",
  "mg": "Madagascar",
  "mw": "Malawi",
  "my": "Malaysia",
  "mv": "Maldives",
  "ml": "Mali",
  "mt": "Malta",
  "mh": "Marshall Islands",
  "mq": "Martinique",
  "mr": "Mauritania",
  "mu": "Mauritius",
  "yt": "Mayotte",
  "mx": "Mexico",
  "fm": "Micronesia",
  "md": "Moldova",
  "mc": "Monaco",
  "mn": "Mongolia",
  "me": "Montenegro",
  "ms": "Montserrat",
  "ma": "Morocco",
  "mz": "Mozambique",
  "mm": "Myanmar",
  "na": "Namibia",
  "nr": "Nauru",
  "np": "Nepal",
  "nl": "Netherlands",
  "nc": "New Caledonia",
  "nz": "New Zealand",
  "ni": "Nicaragua",
  "ne": "Niger",
  "ng": "Nigeria",
  "nu": "Niue",
  "nf": "Norfolk Island",
  "mp": "Northern Mariana Islands",
  "no": "Norway",
  "om": "Oman",
  "pk": "Pakistan",
  "pw": "Palau",
  "ps": "Palestine",
  "pa": "Panama",
  "pg": "Papua New Guinea",
  "py": "Paraguay",
  "pe": "Peru",
  "ph": "Philippines",
  "pn": "Pitcairn",
  "pl": "Poland",
  "pt": "Portugal",
  "pr": "Puerto Rico",
  "qa": "Qatar",
  "re": "Réunion",
  "ro": "Romania",
  "ru": "Russian Federation",
  "rw": "Rwanda",
  "bl": "Saint Barthélemy",
  "sh": "Saint Helena",
  "kn": "Saint Kitts and Nevis",
  "lc": "Saint Lucia",
  "mf": "Saint Martin (French part)",
  "pm": "Saint Pierre and Miquelon",
  "vc": "Saint Vincent and the Grenadines",
  "ws": "Samoa",
  "sm": "San Marino",
  "st": "Sao Tome and Principe",
  "sa": "Saudi Arabia",
  "sn": "Senegal",
  "rs": "Serbia",
  "sc": "Seychelles",
  "sl": "Sierra Leone",
  "sg": "Singapore",
  "sx": "Sint Maarten (Dutch part)",
  "sk": "Slovakia",
  "si": "Slovenia",
  "sb": "Solomon Islands",
  "so": "Somalia",
  "za": "South Africa",
  "gs": "South Georgia and the South Sandwich Islands",
  "ss": "South Sudan",
  "es": "Spain",
  "lk": "Sri Lanka",
  "sd": "Sudan",
  "sr": "Suriname",
  "sj": "Svalbard and Jan Mayen",
  "sz": "Swaziland",
  "se": "Sweden",
  "ch": "Switzerland",
  "sy": "Syrian Arab Republic",
  "tw": "Taiwan",
  "tj": "Tajikistan",
  "tz": "Tanzania",
  "th": "Thailand",
  "tl": "Timor-Leste",
  "tg": "Togo",
  "tk": "Tokelau",
  "to": "Tonga",
  "tt": "Trinidad and Tobago",
  "tn": "Tunisia",
  "tr": "Turkey",
  "tm": "Turkmenistan",
  "tc": "Turks and Caicos Islands",
  "tv": "Tuvalu",
  "ug": "Uganda",
  "ua": "Ukraine",
  "ae": "United Arab Emirates",
  "gb": "United Kingdom",
  "us": "United States",
  "um": "United States Minor Outlying Islands",
  "uy": "Uruguay",
  "uz": "Uzbekistan",
  "vu": "Vanuatu",
  "ve": "Venezuela",
  "vn": "Viet Nam",
  "vg": "Virgin Islands",
  "vi": "Virgin Islands",
  "wf": "Wallis and Futuna",
  "eh": "Western Sahara",
  "ye": "Yemen",
  "zm": "Zambia",
  "zw": "Zimbabwe"
};

Application.prototype.mailchimp_countries = {
  164: "USA",
  286: "Aaland Islands",
  274: "Afghanistan",
  2: "Albania",
  3: "Algeria",
  178: "American Samoa",
  4: "Andorra",
  5: "Angola",
  176: "Anguilla",
  175: "Antigua And Barbuda",
  6: "Argentina",
  7: "Armenia",
  179: "Aruba",
  8: "Australia",
  9: "Austria",
  10: "Azerbaijan",
  11: "Bahamas",
  12: "Bahrain",
  13: "Bangladesh",
  14: "Barbados",
  15: "Belarus",
  16: "Belgium",
  17: "Belize",
  18: "Benin",
  19: "Bermuda",
  20: "Bhutan",
  21: "Bolivia",
  22: "Bosnia and Herzegovina",
  23: "Botswana",
  181: "Bouvet Island",
  24: "Brazil",
  180: "Brunei Darussalam",
  25: "Bulgaria",
  26: "Burkina Faso",
  27: "Burundi",
  28: "Cambodia",
  29: "Cameroon",
  30: "Canada",
  31: "Cape Verde",
  32: "Cayman Islands",
  33: "Central African Republic",
  34: "Chad",
  35: "Chile",
  36: "China",
  185: "Christmas Island",
  37: "Colombia",
  204: "Comoros",
  38: "Congo",
  183: "Cook Islands",
  268: "Costa Rica",
  275: "Cote D'Ivoire",
  40: "Croatia",
  276: "Cuba",
  298: "Curacao",
  41: "Cyprus",
  42: "Czech Republic",
  318: "Democratic Republic of the Congo",
  43: "Denmark",
  44: "Djibouti",
  186: "Dominica",
  289: "Dominica",
  187: "Dominican Republic",
  45: "Ecuador",
  46: "Egypt",
  47: "El Salvador",
  48: "Equatorial Guinea",
  49: "Eritrea",
  50: "Estonia",
  51: "Ethiopia",
  189: "Falkland Islands",
  191: "Faroe Islands",
  52: "Fiji",
  53: "Finland",
  54: "France",
  193: "French Guiana",
  277: "French Polynesia",
  56: "Gabon",
  57: "Gambia",
  58: "Georgia",
  59: "Germany",
  60: "Ghana",
  194: "Gibraltar",
  61: "Greece",
  195: "Greenland",
  192: "Grenada",
  196: "Guadeloupe",
  62: "Guam",
  198: "Guatemala",
  270: "Guernsey",
  63: "Guinea",
  65: "Guyana",
  200: "Haiti",
  66: "Honduras",
  67: "Hong Kong",
  68: "Hungary",
  69: "Iceland",
  70: "India",
  71: "Indonesia",
  278: "Iran",
  279: "Iraq",
  74: "Ireland",
  322: "Isle of Man",
  75: "Israel",
  76: "Italy",
  202: "Jamaica",
  78: "Japan",
  288: "Jersey  (Channel Islands)",
  79: "Jordan",
  80: "Kazakhstan",
  81: "Kenya",
  203: "Kiribati",
  82: "Kuwait",
  83: "Kyrgyzstan",
  84: "Lao People's Democratic Republic",
  85: "Latvia",
  86: "Lebanon",
  87: "Lesotho",
  88: "Liberia",
  281: "Libya",
  90: "Liechtenstein",
  91: "Lithuania",
  92: "Luxembourg",
  208: "Macau",
  93: "Macedonia",
  94: "Madagascar",
  95: "Malawi",
  96: "Malaysia",
  97: "Maldives",
  98: "Mali",
  99: "Malta",
  207: "Marshall Islands",
  210: "Martinique",
  100: "Mauritania",
  212: "Mauritius",
  241: "Mayotte",
  101: "Mexico",
  102: "Moldova, Republic of",
  103: "Monaco",
  104: "Mongolia",
  290: "Montenegro",
  294: "Montserrat",
  105: "Morocco",
  106: "Mozambique",
  242: "Myanmar",
  107: "Namibia",
  108: "Nepal",
  109: "Netherlands",
  110: "Netherlands Antilles",
  213: "New Caledonia",
  111: "New Zealand",
  112: "Nicaragua",
  113: "Niger",
  114: "Nigeria",
  217: "Niue",
  214: "Norfolk Island",
  272: "North Korea",
  116: "Norway",
  117: "Oman",
  118: "Pakistan",
  222: "Palau",
  282: "Palestine",
  119: "Panama",
  219: "Papua New Guinea",
  120: "Paraguay",
  121: "Peru",
  122: "Philippines",
  221: "Pitcairn",
  123: "Poland",
  124: "Portugal",
  253: "Puerto Rico",
  126: "Qatar",
  315: "Republic of Kosovo",
  127: "Reunion",
  128: "Romania",
  129: "Russia",
  130: "Rwanda",
  205: "Saint Kitts and Nevis",
  206: "Saint Lucia",
  237: "Saint Vincent and the Grenadines",
  132: "Samoa (Independent)",
  227: "San Marino",
  255: "Sao Tome and Principe",
  133: "Saudi Arabia",
  134: "Senegal",
  266: "Serbia",
  135: "Seychelles",
  136: "Sierra Leone",
  137: "Singapore",
  302: "Sint Maarten",
  138: "Slovakia",
  139: "Slovenia",
  223: "Solomon Islands",
  140: "Somalia",
  141: "South Africa",
  257: "South Georgia and the South Sandwich Islands",
  142: "South Korea",
  311: "South Sudan",
  143: "Spain",
  144: "Sri Lanka",
  293: "Sudan",
  146: "Suriname",
  225: "Svalbard and Jan Mayen Islands",
  147: "Swaziland",
  148: "Sweden",
  149: "Switzerland",
  285: "Syria",
  152: "Taiwan",
  260: "Tajikistan",
  153: "Tanzania",
  154: "Thailand",
  233: "Timor-Leste",
  155: "Togo",
  232: "Tonga",
  234: "Trinidad and Tobago",
  156: "Tunisia",
  157: "Turkey",
  158: "Turkmenistan",
  287: "Turks &#38; Caicos Islands",
  159: "Uganda",
  161: "Ukraine",
  162: "United Arab Emirates",
  262: "United Kingdom",
  163: "Uruguay",
  165: "Uzbekistan",
  239: "Vanuatu",
  166: "Vatican City State (Holy See)",
  167: "Venezuela",
  168: "Vietnam",
  169: "Virgin Islands (British)",
  238: "Virgin Islands (U.S.)",
  188: "Western Sahara",
  170: "Yemen",
  173: "Zambia",
  174: "Zimbabwe"
};


$(document).ready(function () {
  new Application();
});
